import React from 'react'

interface OwnProps {
  children: React.ReactNode
}

type Props = OwnProps

const Body: React.FC<Props> = (props) => {
  return <main>{props.children}</main>
}

export default Body
