import React from 'react'
import Link from 'next/link'

import { style } from './SiteFooter.style'
import Content from '../Content/Content'

const SiteFooter: React.FC = () => {
  return (
    <footer css={style.siteFooter}>
      <div css={style.linkBlock}>
        <Link href="/" css={style.footerLink}>
          <span>県民だよりTOPへ戻る</span>
        </Link>
        <a
          href="https://www.pref.niigata.lg.jp/"
          target="_blank"
          rel="noreferrer"
          css={style.footerLink}
        >
          <span>新潟県ホームページへ &gt;&gt;</span>
        </a>
      </div>
      <Content css={style.content}>
        <div css={style.contactBox}>
          <div css={style.addressBlock}>
            <p css={style.team}>発行・編集／新潟県広報広聴課</p>
            <p css={style.address}>新潟県新潟市中央区新光町4番地1</p>
          </div>
          <div css={style.wrapLink}>
            <a href="tel:025-285-5511" css={style.txtStyle}>
              <p>TEL：025-285-5511（代表）</p>
            </a>
            <a href="mailto:ngt000120@pref.niigata.lg.jp" css={style.txtStyle}>
              <p>メールでのお問い合わせ</p>
            </a>
          </div>
        </div>
        <p css={style.copyright}>© Niigata Prefectural Government.</p>
      </Content>
    </footer>
  )
}

export default SiteFooter
