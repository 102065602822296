import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'

import { style } from './SiteHeader.style'
import Content from '../Content/Content'
import { latestIssue } from '../../pages'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const SiteHeader: React.FC = () => {
  return (
    <header css={style.siteHeader} id="site_header">
      <Content css={style.content}>
        <Link href="/" css={style.siteTitle}>
          <div css={style.prefectureBlock}>
            <img src={`${basePath}/common/img/logo.png`} css={style.logo} />
            <span css={style.prefecture}>新潟県</span>
          </div>
          <div css={style.mainLogoBlock}>
            <img
              src={`${basePath}/common/img/mainLogo.png`}
              alt="県民だより"
              css={style.mainLogo}
            />
          </div>
        </Link>
        <div css={style.linkBlock}>
          <Link href={'/backnumber'}>
            <img
              src={`${basePath}/common/img/icon_back.png`}
              alt="バックナンバー"
            />
          </Link>
          <Link href={`/${latestIssue}`}>
            <img src={`${basePath}/common/img/icon_new.png`} alt="最新号" />
          </Link>
        </div>
      </Content>
    </header>
  )
}

export default SiteHeader
