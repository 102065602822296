import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const thisSizePc = '64px';
const thisSizeSp = '45px';

const scrollTopBtn = css`
  bottom: 16px;
  color: ${variables.WHITE};
  height: ${thisSizeSp};
  line-height: ${thisSizeSp};
  position: fixed;
  right: 16px;
  transition: all .6s;
  width: ${thisSizeSp};
  z-index: 99;

  ${minMq('MD')} {
		bottom: 35px;
		height: ${thisSizePc};
		line-height: ${thisSizePc};
		right: 45px;
		width: ${thisSizePc};
  }
  
  &.is-hover > span {
    opacity: .9;
  }

  > span {
    background-color: ${variables.THEME_C};
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    display: block;
    font-size: 2.5rem;
    font-weight: 400;
    height: 100%;
    line-height: inherit;
    line-height: 64px;
    opacity: .6;
    position: relative;
    text-align: center;
    transition: all .6s;
    
    ${minMq('MD')} {
      font-size: 3rem;
    }

    &::before {
      border-bottom: 10px solid ${variables.WHITE};
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      content: "";
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
    
      ${minMq('MD')} {
        border-bottom-width: 15px;
        border-left-width: 14px;
        border-right-width: 14px;
      }
    }
  }
`

const activeStyle = css`
  opacity: 1;
`
const hiddenStyle = css`
  opacity: 0;
  pointer-events: none;
`

export const style = {
  scrollTopBtn,
  activeStyle,
  hiddenStyle
}