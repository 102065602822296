import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const siteFooter = css`
  margin-top: 40px;
  width: 100%;

  ${minMq('MD')} {
    margin-top: 60px;
  }
`

const linkBlock = css`
  > * + * {
    border-top: 1px solid ${variables.WHITE};
  }
`

const footerLink = css`
  background-color: ${variables.THEME_C};
  color: ${variables.WHITE};
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 10px 0;
  text-align: center;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

const content = css`
  padding-bottom: 26px;
  padding-top: 40px;

  ${minMq('MD')} {
    padding-bottom: 50px;
    padding-top: 64px;
  }
`

const contactBox = css`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 43px;

  ${maxMq('MD')} {
    align-items: center;
    flex-direction: column;
    margin-bottom: 58px;
  }
`

const addressBlock = css`
  ${minMq('MD')} {
    flex-basis: 306px;
  }
`

const team = css`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 11px;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

const address = css`
  font-size: 1.4rem;
`

const wrapLink = css`
  display: flex;
  gap: 20px;

  ${maxMq('MD')} {
    flex-direction: column;
  }
`

const txtStyle = css`
  align-items: center;
  background-color: ${variables.THEME_C};
  color: ${variables.WHITE};
  display: flex;
  font-size: 1.6rem;
  font-weight: 700;
  height: 58px;
  justify-content: center;
  width: 290px;

  ${minMq('MD')} {
    font-size: 1.8rem;
    width: 306px;
  }
  `

const copyright = css`
  text-align: center;
`


export const style = {
  siteFooter,
  linkBlock,
  footerLink,
  content,
  contactBox,
  addressBlock,
  team,
  address,
  wrapLink,
  txtStyle,
  copyright
}