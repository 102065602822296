export default {
  WHITE: '#fff',
  BLACK: '#000',
  STEEL_GRAY: '#656678',
  RED: '#e60012',
  GREEN: '#488b4b',
  BLUE: '#81c8f1',
  LIGHT_BLUE: '#D4EFFF',
  LIGHT_YELLOW: '#FFF5C0',
  PINK: '#e3556a',
  YELLOW: '#FFE354',
  DARK_MAGENTA: '#76002c',
  DARK_BLUE: '#174762',
  BASE_BGC: '#fef6e5',
  SUB_BGC: '#f2f2f2',
  BASE_BDC: '#1A1A1A',
  SUB_BDC: '#CCCCCC',
  BASE_TXC: '#1A1A1A',
  OUTER_FRAME: '#d6d6d6',

  // テーマカラー
  THEME_C: '#017DC5',
  SUB_C: '#78ccfd',

  // エラー表示用の強調色
  ERR_C: '#f20606',
  //$err_bgc: tint($err_c, 95%);

  // リンクの色
  LINK_TXC: '#0083f7',
  LINK_HVC: '#18e2e2',

  // ボタンの色
  BTN_BGC: '#fef6e5',
  //$btn_hvc: lighten($theme_c, 15%);
  BTN_TXC: '#4d311c',

  // size
  UNIT: '5px',
  BLOCK_SPACE: `${5 * 6}px`,
  BLOCK_PAD: `${5 * 4}px`,

  CONTENT_W: '1020px',
  HEADER_H_PC: '100px',
  HEADER_H_SP: '60px',
  GLOBAL_NAV_W: '44px',
  LOGO_H_SP: '30px',
  BTN_CONTACT_H_SP: '40px'
}
