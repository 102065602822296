import { css } from 'styled-components'
// import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const btn = css`
  border: 1px solid ${variables.THEME_C};
  border-radius: 5px;
  display: block;
  padding: 12px 40px 12px 20px;
  position: relative;
  width: 200px;

  > span {
    color: ${variables.THEME_C};
    font-weight: 700;
    line-height: 24px;

    &::after {
      border-bottom: 5px solid transparent;
      border-left: 6px solid ${variables.THEME_C};
      border-top: 5px solid transparent;
      content: "";
      height: 0;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
    }
  }
`


export const btnStyle = {
  btn
}