import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const wrap = css`
  background-color: ${variables.THEME_C};
  font-weight: 500;
  margin-bottom: 60px;
  padding-bottom: 50px;
  padding-top: 50px;

  ${minMq('MD')} {
    margin-bottom: 90px;
  }
`

const contentsBox = css`
  background-color: ${variables.WHITE};
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding: 30px 20px;
  position: relative;

  ${minMq('MD')} {
    padding: 42px 90px 48px;
    gap: 60px;
  }

  > img {
    max-width: 280px;
    width: 100%;
  }
`

const tag = css`
  background-color: ${variables.YELLOW};
  font-size: 2rem;
  left: -24px;
  padding: 7px 24px;
  position: absolute;
  top: 26px;

  ${maxMq('MD')} {
    font-size: 1.6rem;
    left: -6px;
    padding: 10px 15px;
    top: -18px;
  }
`

const verticalBox = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  max-width: 480px;
`

const issue = css`
  background-color: ${variables.LIGHT_BLUE};
  display: inline-block;
  margin-bottom: 7px;
  padding: 5px 16px;

  ${minMq('MD')} {
    margin-bottom: 13px;
  }
`

const title = css`
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 20px;

  ${minMq('MD')} {
    font-size: 3.2rem;
    margin-bottom: 40px;
  }
`

const feature = css`
  border-bottom: 1px solid ${variables.BASE_BDC};
  margin-bottom: 16px;
  padding-bottom: 8px;
  
  ${minMq('MD')} {
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
`

const subtitle = css`
  font-size: 1.8rem;

  ${minMq('MD')} {
    font-size: 2rem;
  }
`

const btnBlock = css`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
`


const bannerArea = css`
  background-color: ${variables.SUB_BGC};
  padding-bottom: 68px;
  padding-top: 68px;
`

const content = css`
  column-gap: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  justify-content: center;
  row-gap: 20px;
`

const indexImgLine = css`
  border: 1px solid ${variables.OUTER_FRAME};
`


export const style = {
  wrap,
  contentsBox,
  tag,
  verticalBox,
  issue,
  title,
  feature,
  subtitle,
  btnBlock,
  bannerArea,
  content,
  indexImgLine
}
