import React from 'react'
import Link from 'next/link'

import { style } from './BackNumber.style'
import { btnStyle } from '../Common/Btn.style'
import { BackNumberItems } from './BackNumberItems'

interface OwnProps {
  max: number
  ttl: string
  isViewList: boolean
}

type Props = OwnProps

const BackNumber = (props: Props) => {
  const { max, ttl, isViewList } = props
  const items = []

  let index = 0
  const conditions = max || BackNumberItems.length
  do {
    items.push(BackNumberItems[index])
    index++
  } while (index < conditions && BackNumberItems.length !== index)

  return (
    <section css={style.section}>
      <header css={style.sectionHeader}>
        <h3>{ttl}</h3>
      </header>
      <ul css={style.bkNumList}>
        {items.length
          ? items.map((item, idx) => {
              return (
                <li key={idx}>
                  <Link href={`/${item.href}`} css={style.bkNumItem}>
                    <span>{item.title}</span>
                  </Link>
                </li>
              )
            })
          : ''}
      </ul>
      {isViewList ? (
        <Link href={'/backnumber'} css={[btnStyle.btn, style.btn]}>
          <span>一覧を見る</span>
        </Link>
      ) : (
        ''
      )}
    </section>
  )
}
export default BackNumber
