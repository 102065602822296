import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'

import { style } from '../components/Home/Index.style'
import { btnStyle } from '../components/Common/Btn.style'
import Page from '../components/Page/Page'
import Content from '../components/Content/Content'
import BackNumber from '../components/BackNumber/BackNumber'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const pageTitle = 'ホーム | 新潟県民だより'

// 最新号のフォルダ
export const latestIssue = 'no167'

// 最新号
const topItems = {
  img: `${basePath}/no167/img/no167.png`,
  issue: '令和6年7月21日 発行',
  title: '県民だより 夏号 No.167',
  subtitle: [
    '自分らしくにいがたで輝く'
  ],
  homePage: `/${latestIssue}/`,
  pdf: `${basePath}/no167/pdf/no167.pdf`,
}

const PageTop = () => {
  return (
    <div css={style.wrap}>
      <Content>
        <div css={style.contentsBox}>
          <p css={style.tag}>最新号</p>
          <img
            src={topItems.img}
            alt={`${topItems.title} ${topItems.subtitle.join(' ')}`}
            css={style.indexImgLine}
          />
          <div css={style.verticalBox}>
            <div>
              <p css={style.issue}>{topItems.issue}</p>
              <p css={style.title}>{topItems.title}</p>
              <p css={style.feature}>特集</p>
              <p css={style.subtitle}>
                {topItems.subtitle.map((txt, i) => {
                  return <span key={i}>{txt}</span>
                })}
              </p>
            </div>
            <div css={style.btnBlock}>
              <Link href={topItems.homePage} css={btnStyle.btn}>
                <span>HPで見る</span>
              </Link>
              <a
                href={topItems.pdf}
                target="_blank"
                rel="noreferrer"
                css={btnStyle.btn}
              >
                <span>PDFで見る</span>
              </a>
            </div>
          </div>
        </div>
      </Content>
    </div>
  )
}

const BannerArea = () => {
  return (
    <div css={style.bannerArea}>
      <Content css={style.content}>
        <a
          href="https://www.pref.niigata.lg.jp/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={`${basePath}/home/img/banner01.png`} alt="新潟県HP" />
        </a>
        <a
          href="https://mobile.twitter.com/niigata_press"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={`${basePath}/home/img/banner02.png`}
            alt="新潟県公式X"
          />
        </a>
        <a href="https://komusubiken.pref.niigata.lg.jp/kosodate/" target="_blank" rel="noreferrer">
          <img
            src={`${basePath}/home/img/banner05.png`}
            alt="妊娠・出産・子育てはこちら"
          />
        </a>
        <a
          href="https://www.pref.niigata.lg.jp/sec/kikitaisaku/1356921834049.html"
          target="_blank"
          rel="noreferrer"
        >
          <img src={`${basePath}/home/img/banner06.png`} alt="新潟県防災ナビ" />
        </a>
      </Content>
    </div>
  )
}

const Home = () => {
  return (
    <Page title={pageTitle}>
      <PageTop />
      <Content>
        <BackNumber max={5} ttl="バックナンバー" isViewList={true} />
      </Content>
      <BannerArea />
    </Page>
  )
}
export default Home
