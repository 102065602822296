import { css } from 'styled-components'
import { minMq, maxMq } from './breakPoints'
import variables from './variables'
// import { defineIcoMoon } from '../../shared/styles/icomoon';
// import '../fonts';

const common = css`
  main {
    flex: 1;
    
    @media print {
      flex: none;
    }
  }

  hr {
    border: 1px solid ${variables.SUB_BGC};
    margin: 20px 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    
    &.is-hover {
      img,
      span {
        opacity: .75;
      }
    }
    
    img,
    span {
      display: block;
      max-width: 100%;
      pointer-events: none;
    }
  }
  
  button {
    cursor: pointer;
  }

  [data-mq='sp'] {
    ${minMq('MD')} {
      display: none !important;
    }
  }

  [data-mq='pc'] {
    ${maxMq('MD')} {
      display: none !important;
    }
  }
`

export default common
