import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const section = css`
  margin-bottom: 70px;
  margin-top: 20px;

  ${minMq('MD')} {
    margin-bottom: 100px;
    margin-top: 60px;
  }
`

const sectionHeader = css`
  align-items: center;
  border-left: 8px solid ${variables.THEME_C};
  display: flex;
  height: 44px;
  margin-bottom: 15px;
  padding-left: 10px;

  ${minMq('MD')} {
    margin-bottom: 26px;
    height: 50px;
    padding-left: 16px;
    border-width: 10px;
  }

  > h3 {
    color: ${variables.THEME_C};
    font-size: 2.6rem;

    ${minMq('MD')} {
      font-size: 3.2rem;
    }
  }
`

const bkNumList = css`
  margin-bottom: 30px;

  > li {
    border-bottom: 1px solid ${variables.SUB_BDC};
  }
`

const bkNumItem = css`
  display: block;
  padding: 33px 80px 18px 0;
  position: relative;

  ${maxMq('MD')} {
    padding-top: 28px;
    padding-bottom: 13px;
  }

  &::after {
    border-bottom: 5px solid transparent;
    border-left: 6px solid ${variables.BASE_BDC};
    border-top: 5px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: 20px;
    top: 54%;
    width: 0;
  }
`

const btn = css`
  margin: 0 auto;
`


export const style = {
  section,
  sectionHeader,
  bkNumList,
  bkNumItem,
  btn,
}