import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const siteHeader = css`
  background-color: ${variables.WHITE};
  height: ${variables.HEADER_H_SP};
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  ${minMq('MD')} {
    height: ${variables.HEADER_H_PC};
  }
  
  @media print {
    position: static;
  }
`

const content = css`
  align-items: center;
  display: flex;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
`

const siteTitle = css`
  align-items: center;
  display: flex;
  position: static;
  z-index: 1;
`

const prefectureBlock = css`
  align-items: center;
  display: flex;
  flex-shrink: 1;
`

const logo = css`
  margin-right: 5px;
  width: 22px;

  ${minMq('MD')} {
    margin-right: 12px;
    width: 32px;
  }
`

const prefecture = css`
  font-size: 1.7rem;
  font-weight: 700;
  margin-right: 10px;

  ${minMq('MD')} {
    font-size: 2.4rem;
    margin-right: 30px;
  }
`

const mainLogoBlock = css`
  flex: 1;
`

const mainLogo = css`
  max-width: 160px;
  width: 100%;
  
  ${minMq('MD')} {
    max-width: 250px;
  }
`

const linkBlock = css`
  display: flex;
  gap: 15px;
  
  ${minMq('MD')} {
    gap: 25px;
  }

  img {
    max-width: 40px;
    width: 100%;
  }
`

export const style = {
  siteHeader,
  content,
  siteTitle,
  prefectureBlock,
  logo,
  prefecture,
  mainLogoBlock,
  mainLogo,
  linkBlock
}
